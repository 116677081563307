html, body { overflow-x: hidden; }
@import "settings";
@import "mixins";

/*****************************************************************************
min-width: 1430px
*****************************************************************************/
@media only screen and (min-width: 1430px) {

	.container {
		max-width: 1430px;
	}

}

/*****************************************************************************
1700px - 1800px
*****************************************************************************/
@media only screen and (min-width: 1700px) and (max-width: 1800px) {

	#Header .header-content .right-col {
	    width: 1387px;
	}

}

/*****************************************************************************
max-width: 1699px
*****************************************************************************/
@media only screen and (max-width: 1699px) {

	#Header .header-content {
		.left-col {
			a.logo {
				width: 300px;
				height: 253px;
			}
			&.logo-sb {
				top: -15px;
				a.logo {
					width: 300px;
					height: 253px;
				}
			}
		}
		.right-col {
			width: 1345px;
		}
	}

	#Header.sticky .header-content .left-col.logo-sb {
		top: -15px;
		a.logo {
	    width: 200px;
	    height: 168px;
		}
	}

	#slider-main-content {
		.slider-main .slide .content-wrapper {
			top: 100px;
			width: 1380px;
			.desc {
				h2 {
					font-size: 52px;
					line-height: 52px;
				}
				p {
					font-size: 24px;
				}
			}
		}
		.bx-controls {
			top: 365px;
		}
	}

	#animation-offer {
		margin-top: -120px;
		.animation-offer__content {
			height: 120px;
		}
	}

	#offer-home {
    margin-top: 51px;
	}

	#about-us {
		margin-top: -120px;
		height: 685px;
		background-size: 100%;
		.inside {
			padding-top: 200px;
		}
	}

	#blog-home {
		.blog-content {
			padding-left: 0;
		}
		.items {
			justify-content: center;
			.item:first-child {
				margin-left: 35px;
			}
			.more {
				display: none;
			}
		}
	}

	#gallery-home .items .item a {
		width: 360px;
		height: 275px;
	}

	#join-customers {
		height: 466px;
		background-size: 100%;
	}

}

/*****************************************************************************
max-width: 1575px
*****************************************************************************/
@media only screen and (max-width: 1560px) {

	#Header .header-content .right-col {
		width: 1202px;
	}

}

/*****************************************************************************
max-width: 1429px
*****************************************************************************/
@media only screen and (max-width: 1429px) {

	#Header {
		padding: 0 30px;
		.header-content {
			.left-col {
				left: 30px;
				a.logo {
					width: 220px;
					height: 185px;
				}
				&.logo-sb {
					top: 0;
					a.logo {
						width: 220px;
						height: 185px;
					}
				}
			}
			.right-col {
				width: calc(100% - 160px);
				.top-bar {
					.phone p.text {
						margin-right: 20px;
					}
					.email {
						margin-left: 40px;
					}
				}
			}
		}
	}

	#Header.sticky .header-content .left-col.logo-sb {
		top: 0;
		a.logo {
	    width: 200px;
	    height: 168px;
		}
	}

	#menu > ul > li > a {
		padding: 0 20px;
	}

	#slider-main-content {
		.slider-main .slide .content-wrapper {
			top: 100px;
			width: 1100px;
			.desc {
				width: 580px;
				h2 {
					font-size: 42px;
					line-height: 44px;
				}
				p {
					font-size: 20px;
				}
			}
		}
		.bx-controls {
			top: 365px;
		}
	}

	#animation-offer {
		margin-top: -90px;
		.animation-offer__content {
			height: 90px;
			.active-item {
				width: 140px;
				&.position2 {
					left: 188px;
				}
				&.position3 {
					left: 350px;
				}
				&.position4 {
					left: 526px;
				}
				&.position5 {
					left: 710px;
				}
				&.position6 {
					left: 955px;
					width: 155px;
				}
			}
		}
	}

	#offer-home {
		.items .item {
			width: 540px;
			overflow: hidden;
			h2 {
				font-size: 40px;
				line-height: 40px;
			}
			&.offer-blue {
				.photo {
					right: -63px;
				}
			}
			&.offer-yellow {
				.photo {
					right: -85px;
				}
			}
		}
		.offer-pink {
			margin-top: 45px;
			.inside {
				margin-left: 50px;
			}
			.photo {
				right: 0;
			}
		}
	}

	#about-us {
		margin-top: -80px;
		height: 575px;
		.inside {
			padding-top: 160px;
			.right-col {
				width: 540px;
			}
			h3 {
				margin-bottom: 25px;
				padding-bottom: 8px;
				font-size: 26px;
			}
			p {
				margin-bottom: 20px;
				font-size: 16px;
				br {
					display: none;
				}
			}
		}
	}

	#blog-home .items .item {
		margin-right: 25px;
		&:first-child {
			margin-left: 25px;
		}
	}

	#contact-home .inside {
		.left-col h4 {
			font-size: 48px;
		}
		.right-col {
			padding-top: 31px;
			h5 {
				font-size: 27px;
			}
		}
	}

	#gallery-home {
		padding-top: 170px;
		background-size: 100%;
		.items .item {
			&:nth-child(2n){
				margin-top: 40px;
			}
			a {
				width: 295px;
				height: 225px;
			}
		}
	}

	#opinions-home {
		.inside {
			padding-bottom: 100px;
		}
		.items .item {
			margin-right: 20px;
			padding-right: 40px;
		}
	}

	#join-customers {
		height: 405px;
		.text {
			h3 {
				padding-top: 0;
				height: 100%;
				font-size: 32px;
				a {
					display: block;
					width: 100%;
					height: 100%;
					padding: 82px 0 0;
				}
			}
		}
	}

	.subpage-simple {
		margin: 40px 0 90px;
	}

	.subpage-blog {
		padding-top: 80px;
		.inside {
			padding: 0;
			.item {
				width: 350px;
				a .photo {
					height: 214px;
					margin-bottom: 25px;
				}
			}
		}
	}

	.subpage-blog-details {
		margin: 60px 0 90px;
		.inside {
			.blog-details {
				width: 67%;
				.blog-details__header {
					margin-bottom: 30px;
					h3 {
						padding-bottom: 20px;
						font-size: 26px;
						line-height: 32px;
					}
				}
				img {
					margin: 20px 0;
				}
			}
			.blog-sidebar {
				width: 30%;
				aside {
					margin-bottom: 20px;
					h3 {
						margin-bottom: 30px;
						padding-bottom: 15px;
					}
				}
				.widget-latest-news {
					.items {
						.item a {
							.desc {
								width: 230px;
								p {
									&.title {
										font-size: 14px;
										line-height: 17px;
									}
								}
							}
						}
					}
				}

			}
		}
	}

	.subpage-gallery {
		margin-top: 80px;
		.items .item a {
			width: 353px;
			height: 269px;
		}
	}

	.subpage-shop {
		margin-top: 80px;
		.items .item {
			margin-right: 25px;
			width: 353px;
			a {
				.photo {
					width: 353px;
					height: 250px;
				}
				.desc h4 {
					font-size: 22px;
				}
			}
		}
	}

	.subpage-product .inside {
		.left-col {
			width: 500px;
			.photo {
				height: 400px;
			}
		}
		.right-col {
			h3 {
				margin-bottom: 15px;
				padding-bottom: 15px;
				font-size: 26px;
			}
			.stars {
				margin-bottom: 20px;
				width: 120px;
				height: 20px;
			}
			.bottom {
				.right {
					.price {
						strong {
							font-size: 36px;
						}
					}
				}
			}
		}
	}

	.subpage-product-list {
		margin-bottom: 40px;
		.items .item {
			margin-right: 20px;
			width: 206px;
			a {
				.photo {
					width: 206px;
					height: 146px;
				}
				.desc h5 {
					font-size: 14px;
				}
			}
		}
	}

	.subpage-offer .points {
		margin-bottom: 110px;
		ul {
			margin: 0;
			justify-content: space-between;
			li {
				width: 48%;
				&:nth-child(2n) {
					margin-left: 0;
				}
			}
		}
	}

	.subpage-offer .subpage-offer-yellow ul.half {
		margin-bottom: 20px;
	}

	#gallery-home.subpage {
		margin-bottom: 110px;
	}

	.offer-sb-blue .inside {
		padding-left: 60px;
		h2 {
			font-size: 70px;
			line-height: 70px;
		}
		p {
			font-size: 27px;
		}
		.photo {
			right: 50px;
		}
	}

	.offer-sb-yellow .inside {
		padding-left: 60px;
		h2 {
			font-size: 70px;
			line-height: 70px;
		}
		p {
			font-size: 27px;
		}
		.photo {
			right: 50px;
		}
	}

	.subpage-about-us {
		margin-top: 80px;
		.my-row {
			margin-bottom: 60px;
			.items {
				.photo {
					width: 470px;
					height: 353px;
					&:before {
						top: -20px;
						right: -20px;
						width: 150px;
						height: 142px;
					}
					&:after {
						bottom: -20px;
						left: -20px;
						width: 150px;
						height: 142px;
					}
				}
				.desc {
					width: 600px;
					h3 {
						margin-top: 10px;
						font-size: 26px;
						line-height: inherit;
					}
				}
			}
			.photos .item {
				width: 356px;
        height: 267px;
			}
		}
	}

}

/*****************************************************************************
max-width: 1199px
*****************************************************************************/
@media only screen and (max-width: 1199px) {

	#nav-icon {
		display: block;
	}
	#menu {
		display: none;
	}

	#Header {
		padding: 0 40px;
		.header-content {
			.left-col {
				top: -31px;
				left: 23px;
				a.logo {
					width: 220px;
					height: 185px;
				}
				&.logo-sb {
					top: -31px;
					a.logo {
						width: 182px;
						height: 153px;
					}
				}
			}
			.right-col {
				.top-bar {
					display: none;
				}
				.menu-bar {
					border-top: 0;
				}
			}
		}
	}
	#Header.sticky .header-content .left-col a.logo {
		width: 220px;
		height: 185px;
	}
	#Header.sticky .header-content .left-col.logo-sb {
		top: -31px;
		a.logo {
			width: 182px;
			height: 153px;
		}
	}

	#Content {
    padding-top: 86px;
	}

	h3.line {
		font-size: 26px;
	}

	#slider-main-content {
		.slider-main .slide .content-wrapper {
			top: 90px;
			width: 930px;
			.desc {
				width: 502px;
				h2 {
					margin-bottom: 32px;
					font-size: 36px;
					line-height: 38px;
				}
				p {
					font-size: 18px;
				}
			}
		}
		.bx-controls {
			top: inherit;
			bottom: 20px;
			.bx-pager {
				margin-left: 487px;
			}
		}
	}

	#animation-offer {
		display: none;
	}

	#offer-home {
		margin-top: 40px;
		.items .item {
			width: 458px;
			height: 218px;
			a {
				padding: 45px 0 0 35px;
			}
			h2 {
				font-size: 34px;
				line-height: 34px;
			}
			p {
				line-height: 19px;
			}
			.btn-white {
		    left: 35px;
		    bottom: 22px;
				font-size: 14px;
				line-height: 45px;
			}
			&.offer-blue {
				.photo {
					right: 0;
					bottom: 0;
					top: inherit;
					width: 220px;
					height: 199px;
					background-size: 220px;
				}
			}
			&.offer-yellow {
				.photo {
					right: -24px;
					width: 277px;
					height: 218px;
					background-size: 277px;
				}
			}
		}
		.offer-pink {
			margin-top: 30px;
			height: 350px;
			h2 {
				width: 380px;
				height: 139px;
				background-size: 100%;
			}
			p {
				font-size: 18px;
			}
			.inside {
				margin-left: 30px;
				padding-top: 45px;
			}
			.photo {
				right: -55px;
				width: 555px;
				height: 100%;
				background-size: 100%;
			}
		}
	}

	#about-us {
		margin-top: -70px;
		height: inherit;
		.inside {
			padding-top: 150px;
			.right-col {
				width: 458px;
			}
			h3 {
				margin-bottom: 25px;
				padding-bottom: 8px;
				font-size: 22px;
			}
			p {
				margin-bottom: 15px;
				font-size: 15px;
			}
		}
	}

	#blog-home {
		h3.line {
			margin-bottom: 55px;
			font-size: 26px;
		}
		.items .item {
			margin-right: 20px;
			width: 304px;
			&:first-child {
				margin-left: 20px;
			}
			a {
				.photo {
					margin-bottom: 18px;
					width: 304px;
					height: 186px;
				}
				h5 {
					margin-bottom: 10px;
					font-size: 16px;
					line-height: 25px;
				}
			}
		}
	}

	#contact-home {
		margin-top: 50px;
		height: 180px;
		.inside {
			align-items: center;
			height: 180px;
			.left-col {
				padding-top: 0;
				padding-right: 65px;
				h4 {
					font-size: 44px;
				}
			}
			.right-col {
				padding-top: 0;
				h5 {
					font-size: 27px;
				}
			}
		}
	}

	#gallery-home {
		padding-top: 170px;
		background: none;
		.items {
			margin-top: 60px;
			.item {
				&:nth-child(2n){
					margin-top: 30px;
				}
				a {
					width: 260px;
					height: 198px;
				}
			}
		}
	}

	#opinions-home {
		margin-top: 100px;
		.inside {
			padding-bottom: 70px;
		}
		.items .item {
			margin-right: 12px;
			padding-left: 35px;
			padding-right: 35px;
			background-size: 25px;
			p.txt {
				margin-bottom: 20px;
				font-size: 15px;
				line-height: 25px;
			}
		}
	}

	#partners {
		padding-top: 80px;
		h3 {
			margin-bottom: 60px;
			font-size: 24px;
		}
		.slider-partners-content .slide img {
			max-width: 70%;
		}
	}

	#join-customers {
		margin-top: 80px;
		height: 350px;
		.text {
			h3 {
				font-size: 26px;
				a {
					padding: 82px 0 0;
				}
			}
		}
	}

	#Footer .menu .inside .menu-list li {
		padding: 0 28px;
	}

	.subpage-simple {
		margin: 30px 0 80px;
	}

	.subpage-blog {
		padding-top: 65px;
		.inside {
			.item {
				width: 293px;
				margin-right: 20px;
				a {
					.photo {
						height: 179px;
						margin-bottom: 20px;
					}
					h5 {
						font-size: 15px;
						line-height: 23px;
					}
				}
			}
		}
	}

	.subpage-blog-details {
		margin: 50px 0 60px;
		.inside {
			.blog-details {
				.blog-details__header {
					margin-bottom: 30px;
					h3 {
						padding-bottom: 20px;
						font-size: 22px;
						line-height: 28px;
					}
				}
			}
			.blog-sidebar {
				aside {
					h3 {
						margin-bottom: 30px;
						padding-bottom: 10px;
						font-size: 16px;
						line-height: 20px;
					}
				}
				.widget-latest-news .items .item a {
					padding-bottom: 12px;
					.desc {
						width: 177px;
						p.date {
							margin-bottom: 2px;
							font-size: 12px;
						}
						p.title {
							font-size: 13px;
						}
					}
				}
			}
		}
	}

	.subpage-gallery {
		margin-top: 65px;
		.items .item {
			margin-bottom: 20px;
			margin-right: 20px;
			a {
				width: 296px;
				height: 226px;
			}
		}
	}

	.subpage-shop {
		margin-top: 65px;
		.items .item {
			margin-right: 20px;
			width: 296px;
			a {
				.photo {
					width: 296px;
					height: 210px;
				}
				.desc {
					padding: 35px 15px;
					h4 {
						font-size: 20px;
					}
				}
			}
		}
	}

	.subpage-product {
		margin: 70px 0;
		.inside {
			.left-col {
				width: 430px;
				.photo {
					height: 400px;
				}
			}
			.right-col {
				width: 480px;
				h3 {
					margin-bottom: 12px;
					padding-bottom: 12px;
					font-size: 20px;
				}
				.stars {
					margin-bottom: 20px;
					width: 100px;
					height: 17px;
				}
				.bottom {
					position: inherit;
					bottom: inherit;
					flex-wrap: wrap;
					.left {
						margin-right: 0;
					}
					.right {
						width: inherit;
						display: flex;
						flex-direction: column;
						align-items: end;
						.price {
							strong {
								font-size: 30px;
							}
						}
					}
				}
			}
		}
	}

	.subpage-product-list {
		margin-bottom: 0;
		.items .item {
			margin-right: 15px;
			width: 173px;
			a {
				.photo {
					width: 173px;
					height: 123px;
				}
				.desc {
					padding: 18px 15px;
					h5 {
						font-size: 14px;
					}
				}
			}
		}
	}

	.subpage-contact {
		margin: 70px 0;
		.inside {
			.left-col {
				width: 48%;
				padding-right: 30px;
				h3 {
					font-size: 22px;
				}
				p {
					font-size: 17px;
				}
			}
			.right-col {
				width: 52%;
				padding-left: 40px;
				h3 {
					margin-bottom: 30px;
					font-size: 26px;
					line-height: inherit;
				}
			}
		}
	}

	.subpage-offer {
		margin-top: 50px;
		.points {
			margin-bottom: 90px;
			ul {
				li {
					font-size: 17px;
				}
			}
		}
	}

	#offer-home.subpage .offer-pink .inside {
		padding-top: 80px;
	}

	#gallery-home.subpage {
		margin-bottom: 80px;
	}

	.offer-sb-blue .inside {
		padding-left: 60px;
		height: 350px;
		h2 {
			font-size: 60px;
			line-height: 60px;
		}
		p {
			font-size: 25px;
		}
		.photo {
			right: 50px;
			width: 373px;
			height: 100%;
			background-size: 100%;
		}
	}

	.offer-sb-yellow .inside {
		padding-left: 60px;
		height: 350px;
		h2 {
			font-size: 60px;
			line-height: 60px;
		}
		p {
			font-size: 25px;
		}
		.photo {
			right: 0px;
			width: 436px;
			height: 100%;
			background-size: 100%;
		}
	}

	.subpage-offer .subpage-offer-yellow h4 {
		font-size: 21px;
	}

	.subpage-about-us {
		margin-top: 60px;
		.my-row {
			margin-bottom: 60px;
			.items {
				.photo {
					width: 400px;
					height: 300px;
					&:before {
						top: -15px;
						right: -15px;
						width: 140px;
						height: 140px;
					}
					&:after {
						bottom: -15px;
						left: -15px;
						width: 140px;
						height: 140px;
					}
				}
				.desc {
					width: 500px;
					h3 {
						margin-top: 10px;
						font-size: 22px;
					}
					p {
						margin-bottom: 20px;
					}
				}
			}
			.quote {
				margin-top: 30px;
				font-size: 16px;
				line-height: 28px;
			}
			.photos .item {
				width: 300px;
        height: 225px;
			}
		}
	}
	.text-line h3 {
		margin-right: 18px;
		font-size: 28px;
	}

}


/*****************************************************************************
max-width: 991px
*****************************************************************************/
@media only screen and (max-width: 991px) {

	.responsive-block-open #Wrapper {
		left: 0;
	}
	#responsive-block {
		left: -260px;
		width: 260px;
	}

	#Header {
		padding: 0 30px;
		.header-content {
			.left-col a.logo {
				width: 200px;
				height: 168px;
			}
			.left-col.logo-sb a.logo {
				width: 170px;
				height: 143px;
			}
			.right-col .menu-bar {
				height: 80px;
			}
		}
	}
	#Header.sticky .header-content .left-col a.logo {
		width: 200px;
		height: 168px;
	}
	#Header.sticky .header-content .left-col.logo-sb a.logo {
		width: 170px;
		height: 143px;
	}

	#Content {
		padding-top: 80px;
	}

	h3.line {
    font-size: 24px;
	}

	#slider-main-content {
		.slider-main .slide .content-wrapper {
			top: 57px;
			width: 690px;
			.desc {
				width: 358px;
				h2 {
					margin-bottom: 15px;
					font-size: 26px;
					line-height: 32px;
				}
				p {
					margin-bottom: 20px;
					font-size: 16px;
				}
				a.btn-more {
					padding: 0 40px;
					font-size: 14px;
					height: 55px;
					line-height: 55px;
				}
			}
		}
		.bx-controls {
			top: inherit;
			bottom: 20px;
			.bx-pager {
				margin-left: 487px;
			}
		}
	}

	#offer-home {
		margin-top: 30px;
		.items .item {
			width: 340px;
			height: 218px;
			a {
				padding: 71px 0 0 25px;
				@include transition(all 0.4s ease);
			}
			h2 {
				font-size: 28px;
				line-height: 28px;
			}
			p {
				width: 175px;
				font-size: 14px;
				line-height: 19px;
				br {
					display: none;
				}
			}
			.btn-white {
				display: none;
			}
			&.offer-blue {
				a:hover {
					background-color: #2ea8c9;
				}
				.photo {
					right: -43px;
					bottom: 0;
					top: inherit;
					width: 220px;
					height: 199px;
					background-size: 220px;
				}
			}
			&.offer-yellow {
				a:hover {
					background-color: #dfaf2f;
				}
				.photo {
					right: -50px;
					width: 277px;
					height: 218px;
					background-size: 277px;
				}
			}
		}
		.offer-pink {
			margin-top: 20px;
			height: 260px;
			a {
				@include transition(all 0.4s ease);
				&:hover {
					background-color: #dd7490;
				}
			}
			h2 {
				width: 300px;
				height: 110px;
			}
			p {
				font-size: 18px;
			}
			.btn-white {
				display: none;
			}
			.inside {
				width: 370px;
				margin-left: 10px;
				padding-top: 30px;
			}
			.photo {
				right: -44px;
				width: 413px;
			}
		}
	}

	#about-us {
		margin-top: 40px;
		background: none;
		text-align: center;
		.inside {
			padding-top: 0;
			h3 {
				margin-bottom: 20px;
				font-size: 20px;
				color: #292a2e;
				font-weight: 400;
				text-align: center;
				border-bottom: 0;
				&:after {
					content: '';
					display: block;
					margin: 13px auto 0;
					width: 226px;
					height: 2px;
					background: #e2001a;
				}
			}
			.right-col {
				width: 100%;
			}
		}
		.bottom {
			display: block;
			width: 100%;
			height: 290px;
			background: url(../img/rabbit.png) no-repeat center 0;
			background-size: 400px;
		}
	}

	#blog-home {
		h3.line {
			margin-bottom: 45px;
			font-size: 24px;
		}
		.items {
			max-width: 720px;
			margin: 0 auto;
			overflow: hidden;
			justify-content: space-between;
			.item {
				margin-right: 0;
				width: 230px;
				&:first-child {
					margin-left: 0;
				}
				a {
					.photo {
						margin-bottom: 10px;
						width: 230px;
						height: 141px;
					}
					h5 {
						margin-bottom: 10px;
						font-size: 14px;
						line-height: 20px;
					}
				}
			}
		}
	}

	#contact-home {
		margin-top: 45px;
		height: 170px;
		.inside {
			height: 170px;
			.left-col {
				padding-right: 35px;
				h4 {
					font-size: 34px;
				}
			}
			.right-col {
				h5 {
					font-size: 25px;
				}
			}
		}
	}

	#gallery-home {
		padding-top: 150px;
		.items {
			margin-top: 50px;
			.item {
				margin-right: 15px;
				&:nth-child(2n){
					margin-top: 20px;
				}
				a {
					width: 200px;
					height: 153px;
					.border {
						display: none;
					}
					span {
						display: none;
					}
				}
			}
		}
	}

	#opinions-home {
		margin-top: 80px;
		.inside {
			padding-bottom: 60px;
		}
		.items .item {
			margin-right: 25px;
			padding-left: 0;
			padding-right: 25px;
			background: none;
			p.txt {
				margin-bottom: 20px;
				font-size: 15px;
				line-height: 25px;
			}
		}
	}

	#partners {
		padding-top: 60px;
		h3 {
			margin-bottom: 50px;
			font-size: 22px;
		}
		.slider-partners-content .slide img {
			max-width: 70%;
		}
	}

	#join-customers {
		margin-top: 50px;
		height: 289px;
		&.subpage {
			margin-top: 0;
		}
		.text {
			height: 110px;
			h3 {
				font-size: 22px;
				a {
					padding: 57px 0 0;
				}
			}
		}
	}

	#Footer {
		.social-links .inside .social-icons p {
			display: none;
		}
		.menu .inside {
			height: 90px;
			.menu-list li {
				padding: 0 12px;
			}
		}
		.signature .inside {
			height: 50px;
		}
	}

	.subpage-simple {
		margin: 25px 0 70px;
	}

	.subpage-blog {
		padding: 60px 0 40px;
		.inside {
			justify-content: space-between;
			.item {
				width: 330px;
				margin-right: 0;
				margin-bottom: 40px;
				a {
					.photo {
						height: 202px;
						margin-bottom: 10px;
					}
					h5 {
						margin-bottom: 8px;
					}
				}
			}
		}
	}

	.subpage-blog-details {
		margin: 40px 0;
		.inside {
			flex-wrap: wrap;
			.blog-details {
				width: 100%;
			}
			.blog-sidebar {
				width: 100%;
				.widget-latest-news .items .item a {
					padding-bottom: 15px;
					margin-bottom: 15px;
					justify-content: flex-start;
					.photo {
						width: 85px;
						margin-right: 20px;
					}
					.desc {
						width: inherit;
						flex: 1;
						p.date {
							margin-top: 10px;
						}
					}
				}
			}
		}
	}

	.subpage-gallery {
		margin: 60px 0 40px;
		.items .item {
			margin-bottom: 15px;
			margin-right: 15px;
			a {
				width: 220px;
				height: 168px;
				span, .border {
					display: none;
				}
			}
		}
	}

	.subpage-shop {
		margin: 60px 0 40px;
		.items {
			justify-content: space-between;
			.item {
				margin-right: 0;
				width: 330px;
				a {
					.photo {
						width: 330px;
						height: 234px;
					}
					.desc {
						padding: 35px 15px;
						h4 {
							font-size: 18px;
						}
					}
				}
			}
		}
	}

	.subpage-product {
		margin: 50px 0;
		.inside {
			.left-col {
				width: 340px;
				.photo {
					height: 272px;
				}
			}
			.right-col {
				width: 330px;
				h3 {
					margin-bottom: 10px;
					padding-bottom: 10px;
					font-size: 18px;
				}
				.stars {
					margin-bottom: 15px;
					width: 84px;
					height: 14px;
				}
				.bottom {
					position: inherit;
					bottom: inherit;
					flex-wrap: wrap;
					.left {
						margin-right: 0;
					}
					.right {
						width: inherit;
						display: flex;
						flex-direction: column;
						align-items: end;
						.price {
							strong {
								font-size: 30px;
							}
						}
					}
				}
			}
		}
		.photos .item {
			margin-right: 15px;
			a {
				max-width: 150px;
				img {
					height: 120px;
				}
			}
		}
	}

	.subpage-product-list {
		.items .item {
			margin-right: 15px;
			margin-bottom: 20px;
			width: 220px;
			&:nth-child(5n) {
				margin-right: 15px;
			}
			&:nth-child(3n) {
				margin-right: 0;
			}
			a {
				.photo {
					width: 220px;
					height: 156px;
				}
				.desc {
					padding: 18px 15px;
					h5 {
						font-size: 14px;
					}
				}
			}
		}
	}

	.subpage-contact {
		margin: 50px 0 40px;
		.inside {
			flex-wrap: wrap;
			.left-col {
				width: 100%;
				border-right: 0;
				padding-right: 0;
				margin-bottom: 40px;
			}
			.right-col {
				width: 100%;
				padding-left: 0;
				h3 {
					padding-bottom: 5px;
					border-bottom: 1px solid #d0d1d3;
					font-size: 22px;
				}
			}
		}
	}

	.subpage-offer {
		margin-top: 50px;
		.points {
			margin-bottom: 70px;
			ul {
				li {
					padding: 15px 0 15px 30px;
					font-size: 16px;
				}
			}
		}
	}

	#offer-home.subpage {
		margin-top: 50px;
		.offer-pink .inside {
			padding-top: 30px;
		}
	}

	#gallery-home.subpage {
		margin-bottom: 50px;
	}

	.offer-sb-blue {
		margin-top: 50px;
		.inside {
			padding-left: 40px;
			padding-top: 60px;
			height: 260px;
			h2 {
				margin-bottom: 20px;
				font-size: 52px;
				line-height: 52px;
			}
			p {
				font-size: 22px;
			}
			.photo {
				right: 30px;
				width: 277px;
			}
		}
	}

	.offer-sb-yellow {
		margin-top: 50px;
		.inside {
			padding-left: 40px;
			padding-top: 60px;
			height: 260px;
			h2 {
				margin-bottom: 20px;
				font-size: 52px;
				line-height: 52px;
			}
			p {
				font-size: 22px;
			}
			.photo {
				right: -15px;
				width: 324px;
			}
		}
	}

	.subpage-offer {
		.subpage-offer-blue p, .subpage-offer-yellow p {
			font-size: 18px;
		}
		.subpage-offer-blue h5 {
			padding-bottom: 10px;
			font-size: 18px;
		}
		.subpage-offer-yellow {
			h3.yellow{
				font-size: 22px;
			}
			h4 {
				font-size: 19px;
			}
			h5 {
				padding-bottom: 10px;
				font-size: 18px;
				margin-top: 30px;
			}
		}
		ul.half {
			margin-bottom: 70px;
			li {
				padding-left: 30px;
				font-size: 17px;
			}
		}
		ul.normal {
			margin-bottom: 40px;
			li {
				padding-left: 30px;
				font-size: 17px;
			}
		}
	}

	.subpage-about-us {
		margin-top: 50px;
		.my-row {
			margin-bottom: 40px;
			.items {
				flex-wrap: wrap;
				justify-content: center;
				.photo {
					order: 1;
					width: 470px;
					height: 353px;
					margin-bottom: 40px;
					&:before {
						top: -15px;
						right: -15px;
						width: 140px;
						height: 140px;
					}
					&:after {
						bottom: -15px;
						left: -15px;
						width: 140px;
						height: 140px;
					}
				}
				.desc {
					order: 2;
					width: 100%;
					h3 {
						margin-top: 10px;
						font-size: 22px;
					}
					p {
						font-size: 16px;
					}
				}
			}
			.quote {
				margin-top: 30px;
				font-size: 16px;
				line-height: 28px;
			}
			.photos .item {
				width: 220px;
				height: 165px;
			}
		}
	}
	.text-line h3 {
		margin-right: 15px;
		font-size: 24px;
	}

}

/*****************************************************************************
max-width: 767px
*****************************************************************************/
@media only screen and (max-width: 767px) {

	#slider-main-content {
		.slider-main .slide .content-wrapper {
			top: 57px;
			width: 510px;
			.desc {
				width: 278px;
				h2 {
					margin-bottom: 20px;
					font-size: 22px;
					line-height: 28px;
				}
				p {
					display: none;
				}
				a.btn-more {
					padding: 0 30px;
					font-size: 14px;
					height: 50px;
					line-height: 50px;
				}
			}
		}
		.bx-controls {
			top: inherit;
			bottom: 20px;
			.bx-pager {
				margin-left: 487px;
			}
		}
	}

	h3.line {
		font-size: 22px;
		&:after {
			margin-top: 10px;
			width: 170px;
		}
	}

	#offer-home {
		margin-top: 20px;
		.items {
			flex-wrap: wrap;
			.item {
				width: 510px;
				height: 243px;
				a {
					padding: 71px 0 0 25px;
				}
				h2 {
					font-size: 32px;
					line-height: 32px;
				}
				p {
					width: 200px;
					font-size: 15px;
					line-height: 22px;
				}
				&.offer-blue {
					margin-bottom: 15px;
					.photo {
						right: 0;
						width: 356px;
						height: 323px;
						background-size: 100%;
					}
				}
				&.offer-yellow {
					.photo {
						right: 0;
						width: 308px;
						height: 243px;
						background-size: 100%;
					}
				}
			}
		}
		.offer-pink {
			margin-top: 15px;
			height: 243px;
			overflow: hidden;
			h2 {
				margin-left: 0;
				width: 230px;
				height: 85px;
			}
			p {
				font-size: 15px;
				font-weight: 400;
			}
			.inside {
				width: 220px;
				margin-left: 10px;
				padding-top: 20px;
			}
			.photo {
				right: -80px;
				width: 385px;
			}
		}
	}

	#blog-home {
		h3.line {
			margin-bottom: 45px;
			font-size: 22px;
		}
		.items {
			max-width: 540px;
			.item {
				width: 259px;
				&:nth-child(3) {
					display: none;
				}
				a {
					.photo {
						margin-bottom: 10px;
						width: 259px;
						height: 159px;
					}
					h5 {
						margin-bottom: 10px;
						font-size: 14px;
						line-height: 20px;
					}
				}
			}
		}
	}

	#contact-home {
		margin-top: 45px;
		height: inherit;
		background-position: 0 0;
		padding: 40px 0;
		.inside {
			height: inherit;
			flex-direction: column-reverse;
			.left-col {
				width: 100%;
				padding-right: 0;
				text-align: center;
				h4 {
					font-size: 34px;
				}
			}
			.right-col {
				width: 100%;
				padding-left: 0;
				text-align: center;
				h5 {
					margin-bottom: 20px;
					color: #fff;
					font-size: 24px;
				}
			}
		}
	}

	#gallery-home {
		margin-top: 50px;
		padding-top: 0;
		.items {
			margin-top: 40px;
			.item {
				margin-right: 15px;
				&:nth-child(2n){
					margin-top: 17px;
				}
				a {
					width: 140px;
					height: 107px;
				}
			}
		}
	}

	#opinions-home {
		margin-top: 70px;
		p {
			margin-bottom: 50px;
			font-size: 16px;
		}
		.inside {
			padding-bottom: 50px;
		}
		.items .item {
			width: 50%;
			margin-right: 25px;
			padding-left: 0;
			padding-right: 25px;
			background: none;
			p.txt {
				margin-bottom: 20px;
				font-size: 15px;
				line-height: 25px;
			}
			&:last-child {
				display: none;
			}
			&:nth-child(2) {
				margin-right: 0;
				border-right: 0;
			}
		}
	}

	#partners {
		margin: 0 15px;
		padding-top: 50px;
		h3 {
			margin-bottom: 35px;
			font-size: 22px;
		}
		.slider-partners-content .slide img {
			max-width: 70%;
		}
	}

	#join-customers {
		margin-top: 30px;
		height: 239px;
		.text {
			height: 100px;
			h3 {
				font-size: 18px;
				a {
					padding: 50px 0 0;
				}
			}
		}
	}

	#Footer {
		padding-top: 0;
		.social-links .inside {
			height: inherit;
			padding: 20px 0;
			flex-direction: column;
			.social-icons {
				margin-bottom: 10px;
			}
		}
		.menu .inside {
			height: 90px;
			.menu-list li {
				padding: 0 8px;
				font-size: 14px;
			}
		}
		.signature .inside {
			flex-direction: column;
			justify-content: center;
			height: inherit;
			padding: 20px 0;
			text-align: center;
		}
	}

	.subpage-simple {
		margin: 25px 0 60px;
	}

	.subpage-blog {
		padding: 40px 0 30px;
		.inside {
			.item {
				width: 245px;
				margin-bottom: 30px;
				a {
					.photo {
						height: 150px;
						margin-bottom: 10px;
					}
					h5 {
						margin-bottom: 6px;
						font-size: 14px;
						line-height: 21px;
					}
				}
			}
		}
	}

	.subpage-blog-details .inside {
		.blog-details {
			.blog-details__header {
				margin-bottom: 20px;
				h3 {
					font-size: 18px;
					line-height: 22px;
				}
			}
		}
	}

	.subpage-gallery {
		margin: 40px 0 30px;
		.items .item {
			margin-bottom: 15px;
			margin-right: 15px;
			&:nth-child(3n) {
        margin-right: 15px;
      }
			&:nth-child(2n) {
        margin-right: 0;
      }
			a {
				width: 247px;
				height: 188px;
			}
		}
	}

	.subpage-shop {
		margin: 40px 0 30px;
		.items {
			.item {
				width: 245px;
				margin-bottom: 30px;
				a {
					.photo {
						width: 245px;
						height: 174px;
					}
					.desc {
						padding: 30px 15px;
						h4 {
							font-size: 16px;
						}
					}
				}
			}
		}
	}

	.subpage-product {
		margin: 40px 0;
		.inside {
			flex-wrap: wrap;
			.left-col {
				width: 340px;
				margin: 0 auto 30px;
				.photo {
					height: 272px;
				}
			}
			.right-col {
				width: 100%;
				h3 {
					margin-bottom: 10px;
					padding-bottom: 10px;
					font-size: 18px;
				}
				.stars {
					margin-bottom: 15px;
					width: 84px;
					height: 14px;
				}
				.bottom {
					position: inherit;
					bottom: inherit;
					flex-wrap: wrap;
					.left {
						margin-right: 0;
					}
					.right {
						width: inherit;
						display: flex;
						flex-direction: column;
						align-items: end;
						margin-left: 10px;
						.price {
							font-size: 15px;
							strong {
								font-size: 28px;
							}
						}
					}
				}
			}
		}
		.photos .item {
			margin-right: 10px;
			a {
				max-width: 120px;
				img {
					height: 96px;
				}
			}
		}
	}

	.subpage-product-list {
		.items {
			justify-content: space-between;
			.item {
				margin-right: 0;
				margin-bottom: 15px;
				width: 245px;
				&:nth-child(5n) {
					margin-right: 0;
				}
				a {
					.photo {
						width: 245px;
						height: 174px;
					}
					.desc {
						padding: 18px 15px;
						h5 {
							font-size: 14px;
						}
					}
				}
			}
		}
	}

	.subpage-contact {
		margin-top: 40px;
		.inside .left-col h4 {
			margin-bottom: 30px;
		}
	}

	.subpage-offer {
		margin-top: 30px;
		.points {
			margin-bottom: 70px;
			ul {
				li {
					width: 100%;
				}
			}
		}
	}

	#offer-home.subpage {
		margin-top: 40px;
		.offer-pink .inside {
			padding-top: 30px;
		}
	}

	#gallery-home.subpage {
		margin-bottom: 50px;
	}

	.offer-sb-blue {
		margin-top: 40px;
		.inside {
			padding-left: 30px;
			padding-top: 60px;
			height: 243px;
			h2 {
				margin-bottom: 20px;
				font-size: 36px;
				line-height: 36px;
			}
			p {
				font-size: 17px;
			}
			.photo {
				right: 10px;
				width: 259px;
			}
		}
	}

	.offer-sb-yellow {
		margin-top: 40px;
		.inside {
			padding-left: 30px;
			padding-top: 60px;
			height: 243px;
			overflow: hidden;
			h2 {
				margin-bottom: 20px;
				font-size: 36px;
				line-height: 36px;
			}
			p {
				font-size: 17px;
			}
			.photo {
				right: -53px;
				width: 302px;
			}
		}
	}

	.subpage-offer {
		.subpage-offer-blue p {
			font-size: 16px;
		}
		.subpage-offer-blue h5 {
			margin-top: 50px;
			padding-bottom: 10px;
			font-size: 18px;
		}
		ul.half {
			margin-bottom: 50px;
			li {
				padding-left: 25px;
				font-size: 17px;
			}
		}
		ul.normal {
			margin-bottom: 50px;
			li {
				padding-left: 25px;
				font-size: 17px;
			}
		}
		.subpage-offer-yellow {
			margin-bottom: 60px;
			h3.yellow {
				margin-bottom: 30px;
				font-size: 18px;
			}
			h4 {
				margin-top: 40px;
				font-size: 18px;
			}
			p {
				font-size: 16px;
				&.yellow {
					font-size: 18px;
				}
			}
			h5 {
				margin-top: 50px;
				padding-bottom: 10px;
				font-size: 18px;
			}
			ul.half {
				flex-wrap: wrap;
				li {
					width: 100%;
					font-size: 16px;
				}
			}
			ul.normal li {
				font-size: 16px;
			}
		}
	}

	.subpage-about-us {
		.my-row {
			.items {
				.photo {
					width: 400px;
					height: 300px;
				}
				.desc {
					h3 {
						font-size: 19px;
					}
					ul li {
						padding: 10px 0 10px 30px;
						font-size: 15px;
						&:before {
							top: 19px;
						}
					}
					p {
						font-size: 15px;
						line-height: 27px;
					}
				}
			}
			& > h5 {
				margin-top: 5px;
				font-size: 14px;
				line-height: 22px;
			}
			.text-line h3 {
				margin-right: 12px;
				font-size: 21px;
			}
			.quote {
				margin-top: 10px;
				font-size: 15px;
				line-height: 25px;
			}
			.photos {
				flex-wrap: wrap;
				justify-content: center;
				.item {
					margin-bottom: 25px;
					width: 428px;
					height: inherit;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}

}

/*****************************************************************************
max-width: 575px
*****************************************************************************/
@media only screen and (max-width: 575px) {

	#responsive-block {
		width: 250px;
		left: -250px;
	}

	#Header, #Header.sticky {
		padding: 0 15px;
		.header-content {
			.left-col {
				top: -25px;
				left: 10px;
				a.logo {
					width: 160px;
					height: 135px;
				}
			}
			.left-col.logo-sb {
				a.logo {
					width: 160px;
					height: 135px;
				}
			}
			.right-col .menu-bar {
				height: 70px;
			}
		}
	}


	#slider-main-content {
	.slider-main .slide .content-wrapper {
		top: 57px;
		left: 15px;
		right: 15px;
		width: 100%;
		.desc {
			width: 247px;
			h2 {
				margin-bottom: 20px;
				font-size: 18px;
				line-height: 25px;
				font-weight: 600;
			}
			a.btn-more {
				padding: 0 30px;
				font-size: 14px;
				height: 50px;
				line-height: 50px;
			}
		}
	}
	.bx-controls {
		top: inherit;
		bottom: 20px;
		.bx-pager {
			margin-left: 487px;
		}
	}
}

	#Content {
		padding-top: 70px;
	}

	#offer-home {
		margin-top: 15px;
		.items {
			.item {
				width: 100%;
				height: 230px;
				a {
					padding: 71px 0 0 25px;
				}
				h2 {
					font-size: 30px;
					line-height: 30px;
				}
				p {
					width: 200px;
					font-size: 15px;
					line-height: 22px;
				}
				&.offer-blue {
					margin-bottom: 15px;
					.photo {
						right: 0;
						width: 254px;
						height: 230px;
					}
				}
				&.offer-yellow {
					.photo {
						right: -26px;
						width: 292px;
						height: 230px;
					}
				}
			}
		}
		.offer-pink {
			margin-top: 15px;
			height: 230px;
			h2 {
				width: 208px;
				height: 77px;
			}
			p {
				margin-bottom: 0;
				font-size: 15px;
				font-weight: 400;
			}
			.inside {
				width: 195px;
				margin-left: 10px;
				padding-top: 20px;
			}
			.photo {
				right: -90px;
				width: 364px;
			}
		}
	}

	#blog-home {
		h3.line {
			margin-bottom: 40px;
			font-size: 20px;
		}
		.items {
			max-width: 100%;
			margin: 0 15px;
			.item {
				width: 49%;
				a {
					.photo {
						margin-bottom: 10px;
						width: 100%;
						height: 164px;
					}
					h5 {
						margin-bottom: 10px;
						font-size: 14px;
						line-height: 20px;
					}
				}
			}
		}
	}

	#contact-home {
		margin-top: 35px;
		padding: 30px 0;
		.inside {
			.left-col {
				h4 {
					font-size: 24px;
				}
			}
			.right-col {
				h5 {
					margin-bottom: 20px;
					font-size: 20px;
				}
			}
		}
	}

	#gallery-home {
		margin-top: 40px;
		.items {
			margin-top: 40px;
			.item {
				margin-right: 15px;
				&:nth-child(2n){
					margin-top: 15px;
				}
				&:nth-child(4){
					display: none;
				}
				a {
					width: 140px;
					height: 107px;
				}
			}
		}
	}

	#opinions-home {
		margin-top: 50px;
		p {
			margin-bottom: 40px;
			font-size: 14px;
		}
		.inside {
			padding-bottom: 30px;
		}
		.items .item {
			margin-right: 25px;
			padding-right: 25px;
			p.txt {
				margin-bottom: 20px;
				font-size: 15px;
				line-height: 25px;
			}
		}
	}

	#partners {
		padding-top: 40px;
		h3 {
			margin-bottom: 30px;
			font-size: 21px;
		}
	}

	#join-customers {
		margin-top: 30px;
		height: 213px;
		.text {
			height: 90px;
			h3 {
				font-size: 16px;
				a {
					padding: 50px 0 0;
				}
			}
		}
	}

	#Footer {
		.menu .inside {
			height: inherit;
			padding: 20px 0;
			.menu-list {
				flex-wrap: wrap;
				width: 100%;
				li {
					width: 33.3%;
					a {
						display: block;
						width: 100%;
						padding: 8px 0;
						text-align: center;
					}
				}
			}
		}
		.signature {
			p {
				margin-bottom: 10px;
				line-height: 16px;
			}
			.text-right p {
				margin-bottom: 0;
			}
		}
	}

	.subpage-simple {
		margin: 20px 0 50px;
	}

	.subpage-blog {
		padding: 30px 0 25px;
		.inside {
			.item {
				width: 48%;
				margin-bottom: 27px;
				a {
					.photo {
						height: 132px;
						margin-bottom: 5px;
					}
					h5 {
						margin-bottom: 6px;
						font-size: 14px;
						line-height: 20px;
					}
				}
			}
		}
	}

	.subpage-blog-details {
		margin: 35px 0 20px;
		.inside {
			.blog-details {
				.blog-details__header {
					margin-bottom: 15px;
					h3 {
						font-size: 16px;
						line-height: 20px;
					}
				}
			}
			.blog-sidebar {
				aside h3 {
					margin-bottom: 20px;
				}
				.widget-latest-news .items .item a .desc p.date {
					margin-top: 0;
				}
			}
		}
	}

	.subpage-gallery {
		margin: 30px 0 25px;
		.items {
			justify-content: center;
			.item a {
				width: 217px;
				height: 165px;
			}
		}
	}

	.subpage-shop {
		margin: 30px 0 25px;
		.items {
			justify-content: center;
			.item {
				width: 217px;
				margin-bottom: 25px;
				margin-right: 15px;
				&:nth-child(3n) {
					margin-right: 15px;
				}
				&:nth-child(2n) {
					margin-right: 0;
				}
				a {
					.photo {
						width: 217px;
						height: 154px;
					}
					.desc {
						padding: 28px 15px;
						h4 {
							font-size: 16px;
						}
					}
				}
			}
		}
	}

	.subpage-product-list {
		h4 {
			font-size: 20px;
		}
		.items {
			justify-content: center;
			.item {
				margin-bottom: 15px;
				width: 217px;
				margin-right: 15px;
				&:nth-child(3n) {
					margin-right: 15px;
				}
				&:nth-child(2n) {
					margin-right:0;
				}
				a {
					.photo {
						width: 217px;
						height: 154px;
					}
					.desc {
						padding: 18px 15px;
						h5 {
							font-size: 14px;
						}
					}
				}
			}
		}
	}

	.subpage-offer {
		margin-top: 20px;
		.points {
			margin-bottom: 50px;
		}
	}

	#offer-home.subpage {
		margin-top: 30px;
		.offer-pink .inside {
			padding-top: 30px;
		}
	}

	#gallery-home.subpage {
		margin-bottom: 30px;
	}

	.offer-sb-blue {
		margin-top: 30px;
		.inside {
			padding-left: 30px;
			padding-top: 60px;
			height: 230px;
			overflow: hidden;
			h2 {
				margin-bottom: 20px;
				font-size: 32px;
				line-height: 32px;
			}
			p {
				font-size: 16px;
			}
			.photo {
				right: -21px;
				width: 245px;
			}
		}
	}

	.offer-sb-yellow {
		margin-top: 30px;
		.inside {
			padding-left: 30px;
			padding-top: 60px;
			height: 230px;
			overflow: hidden;
			h2 {
				margin-bottom: 20px;
				font-size: 32px;
				line-height: 32px;
			}
			p {
				font-size: 16px;
			}
			.photo {
				right: -60px;
				width: 286px;
			}
		}
	}

	.subpage-offer {
		.subpage-offer-blue p {
			font-size: 16px;
			line-height: 28px;
		}
		.subpage-offer-blue h5 {
			margin-top: 50px;
			padding-bottom: 10px;
			font-size: 18px;
		}
		ul.half {
			margin-bottom: 40px;
			li {
				width: 100%;
				padding-left: 25px;
				font-size: 16px;
			}
		}
		.subpage-offer-yellow {
			margin-bottom: 50px;
			h3.yellow {
				font-size: 19px;
			}
			p {
				font-size: 16px;
				line-height: 28px;
				&.yellow {
					font-size: 16px;
				}
			}
			h4 {
				font-size: 16px;
			}
			h5 {
				margin-top: 20px;
				padding-bottom: 10px;
				font-size: 18px;
			}
			ul.normal {
				margin-bottom: 40px;
			}
			ul.half {
				margin-bottom: 10px;
			}
		}
	}

}

/*****************************************************************************
max-width: 479px
*****************************************************************************/
@media only screen and (max-width: 479px) {

	#slider-main-content .slider-main .slide .content-wrapper {
		top: 40px;
		.desc {
			width: 210px;
			h2 {
				font-size: 16px;
				line-height: 24px;
			}
			a.btn-more {
				height: 44px;
				line-height: 44px;
				padding: 0 20px;
			}
		}
	}

	h3.line {
		font-size: 20px;
		&:after {
			margin-top: 10px;
			width: 170px;
		}
	}

	#offer-home {
		margin-top: 15px;
		.items {
			.item {
				height: 200px;
				a {
					padding: 50px 0 0 25px;
				}
				h2 {
					font-size: 30px;
					line-height: 30px;
				}
				p {
					width: 170px;
				}
				&.offer-blue {
					margin-bottom: 15px;
					.photo {
						right: 0;
						width: 220px;
						height: 200px;
					}
				}
				&.offer-yellow {
					.photo {
						right: -26px;
						width: 254px;
						height: 200px;
					}
				}
			}
		}
		.offer-pink {
			margin-top: 15px;
			height: 200px;
			h2 {
				width: 190px;
				height: 70px;
			}
			.inside {
				width: 195px;
				margin-left: 10px;
				padding-top: 20px;
			}
			.photo {
				right: -92px;
				width: 316px;
			}
		}
	}

	#about-us .inside h3 {
		font-size: 18px;
	}

	#blog-home {
		h3.line {
			margin-bottom: 30px;
			font-size: 18px;
		}
		.items {
			.item {
				a {
					.photo {
						margin-bottom: 10px;
						height: 135px;
					}
					h5 {
						margin-bottom: 10px;
						font-size: 14px;
						line-height: 20px;
					}
				}
			}
		}
	}

	#contact-home {
		margin-top: 35px;
		padding: 25px 0;
		.inside {
			.left-col {
				p {
					font-size: 15px;
				}
				h4 {
					font-size: 22px;
				}
			}
			.right-col {
				h5 {
					margin-bottom: 15px;
					font-size: 18px;
				}
			}
		}
	}

	#gallery-home .items {
		margin-top: 30px;
	}

	#opinions-home p {
		br {
			display: none;
		}
	}

	#partners {
		padding-top: 35px;
		h3 {
			margin-bottom: 25px;
			font-size: 17px;
		}
		.slider-partners-content .slide {
			height: 90px;
		}
	}

	#join-customers {
		margin-top: 20px;
		height: 197px;
		.text {
			height: 90px;
			h3 {
				font-size: 16px;
				a {
					padding: 50px 0 0;
				}
			}
		}
	}

	#Footer {
		padding-bottom: 0;
		.signature p br {
			display: block;
		}
	}

	.subpage-blog {
		.inside {
			justify-content: center;
			.item {
				width: 369px;
				margin-bottom: 35px;
				a {
					.photo {
						height: 226px;
						margin-bottom: 10px;
					}
					h5 {
						margin-bottom: 8px;
						font-size: 17px;
						line-height: 24px;
					}
				}
			}
		}
	}

	.subpage-blog-details {
		margin: 25px 0 15px;
		.inside {
			.blog-details {
				.blog-details__header {
					margin-bottom: 15px;
				}
				img {
					margin: 15px 0;
				}
			}
			.blog-sidebar .widget-latest-news .items .item a .photo {
				margin-right: 15px;
			}
		}
	}

	.subpage-gallery {
		.items {
			.item a {
				width: 187px;
				height: 144px;
			}
		}
	}

	.subpage-shop {
		.items {
			.item {
				width: 390px;
				margin-bottom: 25px;
				margin-right: 0;
				&:nth-child(3n) {
					margin-right: 0;
				}
				a {
					.photo {
						width: 390px;
						height: 277px;
					}
					.desc {
						padding: 30px 15px;
						h4 {
							font-size: 18px;
						}
					}
				}
			}
		}
	}

	.subpage-product .photos .item a {
		width: 110px;
		img {
			height: 88px;
		}
	}

	.subpage-product-list {
		h4 {
			font-size: 20px;
		}
		.items {
			justify-content: center;
			.item {
				margin-bottom: 15px;
				width: 390px;
				margin-right: 0;
				&:nth-child(3n) {
					margin-right: 0;
				}
				a {
					.photo {
						width: 390px;
						height: 277px;
					}
					.desc {
						padding: 25px 15px;
						h5 {
							font-size: 16px;
						}
					}
				}
			}
		}
	}

	.subpage-contact {
		margin: 30px 0 0;
		.inside .left-col h3 {
			font-size: 20px;
		}
	}

	.subpage-offer {
		margin-top: 20px;
		.points {
			margin-bottom: 40px;
		}
	}

	#offer-home.subpage {
		margin-top: 20px;
		.offer-pink .inside {
			padding-top: 20px;
		}
	}

	#gallery-home.subpage {
		margin-bottom: 25px;
	}

	.offer-sb-blue {
		margin-top: 20px;
		.inside {
			padding-left: 20px;
			padding-top: 60px;
			height: 200px;
			h2 {
				margin-bottom: 20px;
				font-size: 28px;
				line-height: 28px;
			}
			p {
				font-size: 15px;
			}
			.photo {
				right: -27px;
				width: 213px;
			}
		}
	}

	.offer-sb-yellow {
		margin-top: 20px;
		.inside {
			padding-left: 20px;
			padding-top: 60px;
			height: 200px;
			h2 {
				margin-bottom: 20px;
				font-size: 28px;
				line-height: 28px;
			}
			p {
				font-size: 15px;
			}
			.photo {
				right: -57px;
				width: 250px;
			}
		}
	}

	.subpage-offer {
		.subpage-offer-blue p {
			font-size: 15px;
			line-height: 26px;
		}
		.subpage-offer-blue h5 {
			margin-bottom: 10px;
			margin-top: 40px;
			padding-bottom: 8px;
			font-size: 16px;
		}
		ul.half {
			margin-bottom: 40px;
			li {
				padding: 10px 0 10px 20px;
				font-size: 15px;
				&:before {
					top: 20px;
				}
			}
		}
		.subpage-offer-yellow {
			h3.yellow {
				font-size: 18px;
			}
			p {
				font-size: 15px;
				line-height: 26px;
			}
			h5 {
				margin-bottom: 10px;
				margin-top: 40px;
				padding-bottom: 8px;
				font-size: 16px;
			}
			ul.normal {
				li {
					padding: 10px 0 10px 20px;
					font-size: 15px;
					&:before {
						top: 20px;
					}
				}
			}
			ul.half {
				margin-bottom: 0;
				li {
					padding: 10px 0 10px 20px;
					font-size: 15px;
					&:before {
						top: 20px;
					}
				}
			}
		}
	}

	.subpage-about-us {
		margin-top: 35px;
		.my-row {
			margin-bottom: 30px;
			.items {
				.photo {
					width: 370px;
					height: 278px;
				}
				.desc {
					h3 {
						font-size: 17px;
					}
					ul li {
						padding: 10px 0 10px 20px;
						font-size: 15px;
						&:before {
							top: 19px;
						}
					}
					p {
						margin-bottom: 15px;
						font-size: 15px;
						line-height: 27px;
					}
				}
			}
			& > h5 {
				margin-top: 5px;
				font-size: 14px;
				line-height: 22px;
			}
			.text-line h3 {
				margin-right: 10px;
				font-size: 19px;
			}
			.quote {
				margin-top: 10px;
				font-size: 15px;
				line-height: 25px;
			}
			.photos {
				.item {
					margin-bottom: 20px;
					width: 100%;
				}
			}
		}
	}

}

/*****************************************************************************
max-width: 419px
*****************************************************************************/
@media only screen and (max-width: 419px) {


	#slider-main-content .slider-main .slide .content-wrapper {
		top: 30px;
		.desc {
			width: 160px;
			h2 {
				margin-bottom: 0;
				font-size: 14px;
				line-height: 20px;
			}
			a.btn-more {
				display: none;
			}
		}
	}

	h3.line {
		font-size: 18px;
		&:after {
			margin-top: 10px;
			width: 170px;
		}
	}

	#offer-home {
		margin-top: 15px;
		.items {
			.item {
				height: 170px;
				a {
					padding: 38px 0 0 20px;
				}
				h2 {
					font-size: 24px;
					line-height: 24px;
				}
				p {
					width: 150px;
					font-size: 14px;
				}
				&.offer-blue {
					margin-bottom: 15px;
					.photo {
						right: -35px;
						width: 188px;
						height: 170px;
					}
				}
				&.offer-yellow {
					.photo {
						right: -26px;
						width: 217px;
						height: 170px;
					}
				}
			}
		}
		.offer-pink {
			margin-top: 15px;
			height: 170px;
			h2 {
				margin-bottom: 7px;
				width: 146px;
				height: 55px;
			}
			p {
				font-size: 13px;
				line-height: 15px;
			}
			.inside {
				width: 123px;
				margin-left: 10px;
				padding-top: 9px;
			}
			.photo {
				right: -84px;
				width: 270px;
			}
		}
	}

	#about-us {
		margin-top: 30px;
		.inside {
			h3 {
				margin-bottom: 15px;
				font-size: 15px;
				&:after {
					width: 160px;
				}
			}
			p {
				margin-bottom: 10px;
				font-size: 14px;
			}
		}
		.bottom {
			height: 210px;
			background-size: 290px;
		}
	}

	#blog-home {
		padding-top: 40px;
		h3.line {
			margin-bottom: 30px;
			font-size: 17px;
		}
		.items {
			flex-wrap: wrap;
			.item {
				width: 100%;
				margin-bottom: 20px;
				a {
					.photo {
						margin-bottom: 10px;
						height: 178px;
					}
					h5 {
						margin-bottom: 5px;
						text-align: center;
					}
					.date {
						text-align: center;
					}
				}
			}
		}
	}

	#contact-home {
		margin-top: 10px;
		padding: 20px 0;
		.inside {
			.left-col {
				p {
					font-size: 14px;
				}
				h4 {
					font-size: 20px;
				}
			}
			.right-col {
				h5 {
					margin-bottom: 15px;
					font-size: 16px;
				}
			}
		}
	}

	#gallery-home {
		margin-top: 30px;
		.items {
			margin-top: 30px;
			.item {
				margin-right: 10px;
				&:nth-child(2n){
					margin-top: 0;
				}
				&:nth-child(3){
					display: none;
				}
				a {
					width: 140px;
					height: 107px;
				}
			}
		}
	}

	#partners {
		h3 {
			font-size: 16px;
		}
		.slider-partners-content .slide img {
			max-width: 60%;
		}
	}

	#opinions-home {
		margin-top: 40px;
		p {
			margin-bottom: 30px;
		}
		.inside {
			padding-bottom: 20px;
		}
		.items .item {
			margin-right: 15px;
			padding-right: 10px;
			p.txt {
				margin-bottom: 10px;
				font-size: 14px;
				line-height: 22px;
			}
		}
	}

	#join-customers {
		margin-top: 15px;
		height: 171px;
		.text {
			height: 90px;
			h3 {
				font-size: 16px;
				a {
					padding: 38px 0 0;
				}
			}
		}
	}

	#Footer {
		.social-links .inside {
			flex-direction: column;
			.links a {
				font-size: 14px;
			}
		}
		.menu .inside {
			padding: 15px 0;
			.menu-list li {
				width: 100%;
			}
		}
	}

	.subpage-simple {
		margin: 20px 0 40px;
	}

	.subpage-blog {
		.inside {
			.item {
				width: 290px;
				margin-bottom: 35px;
				a {
					.photo {
						height: 178px;
						margin-bottom: 10px;
					}
					h5 {
						margin-bottom: 8px;
						font-size: 14px;
						line-height: 20px;
					}
				}
			}
		}
	}

	.subpage-gallery {
		.items {
			.item {
				margin-right: 0;
				&:nth-child(3n) {
					margin-right: 0;
				}
				a {
					width: 100%;
					height: inherit;
				}
			}
		}
	}

	.subpage-shop {
		.items {
			.item {
				width: 290px;
				margin-bottom: 25px;
				&:last-child {
					margin-bottom: 0;
				}
				a {
					.photo {
						width: 290px;
						height: 206px;
					}
					.desc {
						padding: 25px 15px;
						h4 {
							font-size: 16px;
						}
					}
				}
			}
		}
	}

	.subpage-product {
		margin: 20px 0 35px;
		.inside .left-col .photo {
			height: inherit;
		}
	}

	.subpage-product-list {
		h4 {
			margin-bottom: 25px;
			padding-bottom: 10px;
			font-size: 18px;
		}
		.items {
			.item {
				margin-bottom: 15px;
				width: 290px;
				margin-right: 0;
				a {
					.photo {
						width: 290px;
						height: 206px;
					}
					.desc {
						padding: 25px 15px;
						h5 {
							font-size: 16px;
						}
					}
				}
			}
		}
	}

	.subpage-contact {
		margin: 20px 0 0;
		.inside {
			.left-col h3 {
				font-size: 18px;
			}
			.right-col {
				h3 {
					margin-bottom: 20px;
					font-size: 18px;
				}
				.btn-red {
					width: 100%;
				}
			}
		}
	}

	#offer-home.subpage .offer-pink .inside {
		padding-top: 9px;
	}

	.subpage-offer .points ul li {
		font-size: 15px;
		font-weight: 300;
	}

	.offer-sb-blue {
		margin-top: 20px;
		.inside {
			padding-left: 20px;
			padding-top: 32px;
			height: 170px;
			h2 {
				margin-bottom: 10px;
				font-size: 24px;
				line-height: 24px;
			}
			p {
				width: 60%;
				font-size: 14px;
			}
			.photo {
				right: -35px;
				width: 181px;
			}
		}
	}

	.offer-sb-yellow {
		margin-top: 20px;
		.inside {
			padding-left: 20px;
			padding-top: 32px;
			height: 170px;
			h2 {
				width: 110px;
				margin-bottom: 10px;
				font-size: 24px;
				line-height: 24px;
			}
			p {
				width: 60%;
				font-size: 14px;
			}
			.photo {
				right: -49px;
				width: 211px;
			}
		}
	}

	.subpage-about-us {
		margin-top: 30px;
		.my-row {
			.items .photo {
				margin-bottom: 30px;
				width: 270px;
				height: 203px;
				&:before {
					width: 100px;
					height: 100px;
					top: -10px;
					right: -10px;
				}
				&:after {
					width: 100px;
					height: 100px;
					bottom: -10px;
					left: -10px;
				}
			}
			& > h5 {
				margin-bottom: 30px;
			}
		}
	}

}
